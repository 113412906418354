import Header from "./components/header";
import Hero from "./components/hero";
import Services from "./pages/services";

function App() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <Services />
    </div>
  );
}

export default App;
